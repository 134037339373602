import React, { Children, Suspense } from "react";
import { Navigate } from "react-router-dom";
import MentionLegale from "../components/shared/FooterPage/MentionLegale";

import ProduitItemPage from "../components/user/ParcoursAchat/ProduitItemPage";
import CguPage from "../components/shared/FooterPage/Cgu";
import ForgetPassword from "../components/auth/ForgetPassword";
import LoaderSuspense from "../components/common/LoaderSuspense";
import OurProduct from "../components/Product/OurProduct";
import PanierPage from "../components/MonCompte/MonComptePage/Panier/PanierPage";
import PolitiqueConfidentialite from "../components/front/politiqueConfidentialite/PolitiqueConfidentialite";
import Contact from "../components/front/contact/Contact";
import WhoWeAre from "../components/front/whoWeAre/WhoWeAre";
import MonCompteLayout from "../components/MonCompte/MonCompteLayout";
import ProfilPage from "../components/MonCompte/MonComptePage/ProfilPage";
import CommandePage from "../components/MonCompte/MonComptePage/Commande/CommandePage";
import CommandeDetail from "../components/MonCompte/MonComptePage/Commande/CommandeDetail";
import FavorisPage from "../components/MonCompte/MonComptePage/FavorisPage";
import {
  ProtectedRoutes,
  RedirectAuthRoute,
  RedirectPageNotAvailable,
} from "./routerUtils";
import { UserType } from "../utils/api/user/user.type";
import SuccessPaiement from "../components/MonCompte/MonComptePage/validateCommand/SuccessPaiement";
import ValidateCommand from "../components/MonCompte/MonComptePage/validateCommand/ValidateCommand";
import OrderItem from "../components/MonCompte/MonComptePage/OrderItem";
import ResetPassword from "../components/auth/ResetPassword";
import PagesError404 from "../components/shared/PagesError404";
import ProductAgro from "../components/Product/ProductAgro";
import Dashboard from "../components/admin/DashBoardAgro/DashBoard/Dashboard";
import Vendeurs from "../components/admin/DashBoardAgro/Vendeurs/Vendeurs";
import Commandes from "../components/admin/DashBoardAgro/Commandes/Commandes";
import Produits from "../components/admin/DashBoardAgro/Produits/Produits";
import Administration from "../components/admin/DashBoardAgro/Administration/Administration";
import Layout from "../components/admin/DashBoardAgro/Layout/Layout";
import AjoutVendeurs from "../components/admin/DashBoardAgro/Vendeurs/AjoutVendeurs";
import Ajouteradmin from "../components/admin/DashBoardAgro/Administration/Ajouteradmin";
import Profil from "../components/admin/DashBoardAgro/Header/Profil";
import GestionCommandes from "../components/admin/GestionCommande/GestionCommandes";
import DetailVendeurs from "../components/admin/DashBoardAgro/Vendeurs/DetailVendeurs";
import DetailsCommande from "../components/admin/DashBoardAgro/Commandes/DetailsCommande";
import Secteurs from "../components/admin/DashBoardAgro/Secteurs/Secteurs";
import AjoutSecteurs from "../components/admin/DashBoardAgro/Secteurs/AjoutSecteurs";
import DetailSecteurs from "../components/admin/DashBoardAgro/Secteurs/DetailSecteurs";
import PageNotAvailable from "../components/shared/PageNotAvailable";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );
const HomePage = Loader(
  React.lazy(() => import("../components/HomePage/HomePage"))
);

const ParcoursAchat = Loader(
  React.lazy(() => import("../components/user/ParcoursAchat/ParcoursAchat"))
);

export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectPageNotAvailable>
        <HomePage />
      </RedirectPageNotAvailable>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/modifier-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/achats",
    element: <ParcoursAchat />,
  },
  {
    path: "/mentions-legales",
    element: <MentionLegale />,
  },
  {
    path: "/cgu",
    element: <CguPage />,
  },
  {
    path: "/produits",
    element: <OurProduct />,
  },
  {
    path: "/produit/:slug",
    element: <ProduitItemPage />,
  },
  {
    path: "/commande-details",
    element: <OrderItem />,
  },
  {
    path: "politique-de-confidentialite",
    element: <PolitiqueConfidentialite />,
  },
  {
    path: "conditions-generales-de-ventes",
    element: <CguPage />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "a-propos-de-nous",
    element: <WhoWeAre />,
  },
  {
    path: "site-non-disponible",
    element: <PageNotAvailable />,
  },
  {
    path: "/paiement",
    element: <SuccessPaiement />,
  },
  {
    path: "admin/",
    element: <Layout />,
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // {
      //   path: "gestionCommandes",
      //   element: <GestionCommandes />,
      // },
      {
        path: "ajoutVendeur",
        element: <AjoutVendeurs />,
      },
      {
        path: "modifierVendeur/:slug",
        element: <AjoutVendeurs />,
      },
      {
        path: "vendeurs",
        element: <Vendeurs />,
      },
      {
        path: "vendeurs/:slug",
        element: <DetailVendeurs />,
      },
      {
        path: "ajoutSecteur",
        element: <AjoutSecteurs />,
      },
      {
        path: "modifierSecteur/:slug",
        element: <AjoutSecteurs />,
      },
      {
        path: "secteurs",
        element: <Secteurs />,
      },
      {
        path: "secteurs/:slug",
        element: <DetailSecteurs />,
      },
      {
        path: "commandes",
        element: <Commandes />,
      },
      {
        path: "commandes/:slug",
        element: <DetailsCommande />,
      },
      {
        path: "produits",
        element: <Produits />,
      },
      {
        path: "administrateurs",
        element: <Administration />,
      },
      {
        path: "ajouteradmin",
        element: <Ajouteradmin />,
      },
      {
        path: "profil",
        element: <Profil />,
      },
    ],
  },
  {
    path: "mon-compte",
    element: <MonCompteLayout />,
    children: [
      { element: <Navigate to="/mon-compte/profil" replace />, index: true },
      {
        path: "profil",
        element: (
          <ProtectedRoutes
            userType={[
              UserType.superadmin,
              UserType.admin,
              UserType.acheteur,
              UserType.vendeur,
            ]}
          >
            <ProfilPage />
          </ProtectedRoutes>
        ),
      },
      {
        path: "commandes",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <CommandePage />
          </ProtectedRoutes>
        ),
      },
      {
        path: "commandes/:slug",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <CommandeDetail />
          </ProtectedRoutes>
        ),
      },
      {
        path: "panier",
        element: <PanierPage />,
      },
      {
        path: "favoris",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <FavorisPage />
          </ProtectedRoutes>
        ),
      },
    ],
  },
  {
    path: "/panier",
    element: <ValidateCommand />,
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
];
